<script>
import DOMPurify from 'dompurify';
import {
  mapState, mapMutations, mapActions, mapGetters,
} from 'vuex';
import communityMixin from '@shared/mixins/community';
import CommunityGroupBanner from '@shared/components/CommunityGroupBanner.vue';

export default {
  mixins: [communityMixin('collection')],
  components: { CommunityGroupBanner },
  head() {
    let title = 'Chargement...';

    if (this.collection) {
      title = this.collection.name;
    }

    return {
      title,
    };
  },
  computed: {
    ...mapState('community', [
      'collections',
      'groups',
    ]),
    ...mapGetters('community', [
      'communityStore',
    ]),
    collection() {
      return this.collections
        .find((v) => v.uuid === this.$route.params.uuid);
    },
    collectionGroups() {
      return this.groups
        .filter((v) => v.collection?.uuid === this.$route.params.uuid);
    },
    description() {
      return (
        this.collection
        && DOMPurify.sanitize(this.collection.description)
      );
    },
  },
  methods: {
    ...mapMutations('community', [
      'showCollectionForm',
    ]),
    ...mapActions('community', [
      'deleteCollectionDialog',
    ]),
  },
};
</script>

<template>
  <div>
    <b-skeleton v-if="!collection" height="400" />
    <div v-else>
      <div class="box">
        <header class="flex-between-center">
          <h1 class="title is-5 mb-0">
            {{ collection.name }} ({{ collectionGroups.length }})
          </h1>
          <div v-if="canManageCommunityEntity" class="buttons">
            <b-button
              @click="showCollectionForm({ collection })"
            >
              Modifier
            </b-button>
            <b-button
              icon-left="trash"
              type="is-danger"
              outlined
              @click="deleteCollectionDialog({ vm: _self, collection })"
            />
          </div>
        </header>
        <div
          v-if="description"
          class="content mt-5 break-words"
          v-html="description"
        />
      </div>
      <CommunityGroupBanner
        v-for="group in collectionGroups"
        :key="group.uuid"
        :store="communityStore"
        :group="group"
        :hasBanner="false"
      >
        <b-button
          type="is-primary is-custom"
          tag="router-link"
          :disabled="!group.is_accessible"
          :to="{name: 'community_group', params: {uuid: group.uuid}}"
        >
          Voir l'espace
        </b-button>
      </CommunityGroupBanner>
    </div>
  </div>
</template>
